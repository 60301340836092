@charset "UTF-8";
.switcher > input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.switcher > label {
  cursor: pointer;
  text-indent: -9999px;
  width: 33px;
  height: 20px;
  background: rgba(120, 120, 128, 0.16);
  display: block;
  border-radius: 100px;
  position: relative;
}
.switcher > label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
  box-shadow: 0px 1.93548px 0.645161px rgba(0, 0, 0, 0.06), 0px 1.93548px 5.16129px rgba(0, 0, 0, 0.15);
}
.switcher > input:checked + label {
  background: #00DADA;
}
.switcher > input:disabled + label {
  background: #00DADA;
  opacity: 0.5;
}
.switcher > input:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}
.switcher > label:active:after {
  width: 25px;
}

.checkbox {
  display: flex;
  align-items: center;
}
.checkbox > input[type=checkbox] {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: white;
  height: 19px;
  width: 18px;
  border: 1px solid #E4EAF0;
  border-radius: 3.6px;
}
.checkbox > input[type=checkbox]:checked {
  background: #B3F4F4;
  border: 1px solid #00DADA;
}
.checkbox > input[type=checkbox]:hover {
  filter: brightness(90%);
}
.checkbox > input[type=checkbox]:disabled {
  opacity: 0.6;
  pointer-events: none;
}
.checkbox > input[type=checkbox]:after {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  content: url(../imgs/check-mark-icon.svg);
  position: relative;
  left: 20%;
  top: 12%;
  opacity: 0;
}
.checkbox > input[type=checkbox]:checked:after {
  opacity: 1;
}
.checkbox > input[type=checkbox]:disabled:after {
  border-color: #7b7b7b;
}
.checkbox > label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 10px;
  color: #212529;
  padding-left: 8px;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  background-color: #E5E5E5;
}

.app-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
}

.fltr-delete-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  color: rgba(253, 253, 255, 0.91);
  background-color: #EB5757;
  text-align: center;
}
.fltr-delete-btn:hover {
  cursor: pointer;
  background: #00AEAE;
}
.fltr-delete-btn:focus {
  outline: none;
}
.fltr-delete-btn:disabled {
  opacity: 0.5;
}

.fltr-delete-btn:hover {
  background-color: #e15656;
  cursor: pointer;
}

.fltr-second-delete-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #FF3232;
  opacity: 0.6;
}
.fltr-second-delete-btn:hover {
  cursor: pointer;
  background: #00AEAE;
}
.fltr-second-delete-btn:focus {
  outline: none;
}
.fltr-second-delete-btn:disabled {
  opacity: 0.5;
}
.fltr-second-delete-btn:hover {
  cursor: pointer;
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0);
}

.fltr-second-btn, .fltr-second-btn-footer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #909294;
}
.fltr-second-btn:hover, .fltr-second-btn-footer:hover {
  cursor: pointer;
  background: #00AEAE;
}
.fltr-second-btn:focus, .fltr-second-btn-footer:focus {
  outline: none;
}
.fltr-second-btn:disabled, .fltr-second-btn-footer:disabled {
  opacity: 0.5;
}
.fltr-second-btn:hover, .fltr-second-btn-footer:hover {
  cursor: pointer;
  color: #5f5f5f;
  background-color: rgba(255, 255, 255, 0);
}

.fltr-input, .tag-input, .tag-input-active, .move-to-input, .fltr-input-search {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  place-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 30px;
  background: #FFFFFF;
  padding-left: 8px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  color: #212529;
}

.fltr-input:disabled, .tag-input:disabled, .tag-input-active:disabled, .move-to-input:disabled, .fltr-input-search:disabled {
  background: rgba(172, 172, 172, 0.4705882353);
}

.fltr-input::placeholder, .tag-input::placeholder, .tag-input-active::placeholder, .move-to-input::placeholder, .fltr-input-search::placeholder {
  opacity: 0.5;
  font-size: 14px;
  line-height: 30px;
}

.fltr-input:focus, .tag-input:focus, .tag-input-active:focus, .move-to-input:focus, .fltr-input-search:focus {
  border: 1px solid #00AEAE;
  outline: none;
}

.fltr-input-search {
  border: 1px solid #CED4DA;
  border-radius: 26px;
  padding-left: 30px;
  background: url(../imgs/search-icon.svg) no-repeat scroll 14px 10px;
}

.fltr-input-search::placeholder {
  color: #999999;
  opacity: 1;
}

.fltr-input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.fltr-input-file-container, .fltr-input-file-container-mob {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
}

.fltr-input-file-container:hover, .fltr-input-file-container-mob:hover {
  cursor: pointer;
  opacity: 0.8;
}

.fltr-input-file-container-mob {
  height: 40px;
}

.fltr-input-file-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  letter-spacing: -0.15px;
  color: #6C7177;
  padding-left: 7px;
}

.fltr-input-file-btn, .fltr-input-file-btn-mob {
  background: #F2F2F2;
  border-radius: 3px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  letter-spacing: -0.15px;
  min-width: 61px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.fltr-input-file-btn-mob {
  height: 29px;
  font-size: 10px;
}

.fltr-badge-container {
  display: flex;
  flex-wrap: wrap;
}

.fltr-badge, .fltr-badge-active {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  max-width: 120px;
  height: 34px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
}
.fltr-badge-active {
  background: rgba(0, 218, 218, 0.3);
  border: 1px solid #00DADA;
}
.fltr-badge:hover, .fltr-badge-active:hover {
  cursor: pointer;
  border: 1px solid #00DADA;
  background: rgba(0, 218, 218, 0.5);
}

.fltr-badge-text, .fltr-badge-text-active {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  color: #7F8489;
  letter-spacing: -0.15px;
  padding-left: 12px;
  padding-right: 12px;
}
.fltr-badge-text-active {
  color: #212529;
}

.fltr-head-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.fltr-second-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: rgba(108, 113, 119, 0.87);
}

.fltr-switcher-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #212529;
}

.fltr-attention-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  letter-spacing: -0.15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.fltr-attention-description-text, .fltr-attention-description-text-mob {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  letter-spacing: -0.15px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  line-height: 19px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.fltr-attention-description-text-mob {
  text-align: center;
}

.drag-dots {
  width: 12px;
  color: #999999;
  -webkit-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

.drag-dots:after {
  content: "⠿";
  font-size: 25px;
}

.action-dots {
  width: 10px;
  -webkit-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

.action-dots:after {
  content: "";
  font-size: 30px;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head-panel-container, .head-panel-container-mob {
  width: 100%;
}

.head-panel-container-mob {
  display: flex;
  flex-direction: column;
}

.head-panel, .head-panel-mob {
  min-height: 50px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  background-color: black;
  justify-content: center;
  position: relative;
}

.head-panel-mob {
  padding-left: 16px;
  padding-right: 16px;
}

.head-panel-mob-items-container {
  top: 50px;
  z-index: 2000;
  position: absolute;
  color: white;
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.head-item-link, .head-item-link-active {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  margin-left: 20px;
  margin-right: 20px;
  color: #FFFFFF;
  fill: #FFFFFF;
}
.head-item-link-active {
  color: #14FFF1;
  fill: #14FFF1;
}

.head-item-link:hover, .head-item-link-active:hover {
  color: #00AEAE;
  fill: #00AEAE;
}

.head-item-text {
  padding-left: 9px;
}

.head-item-logout {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  color: #F13838;
}

.head-item-logout:hover {
  cursor: pointer;
  color: rgba(241, 56, 56, 0.84);
}

.head-item-logo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 4px solid white;
}

.head-item-logo-text {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.head-item-logo-container {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  align-items: center;
  margin-right: auto;
}

.modal {
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  transition: 1.1s ease-out;
  transform: scale(1);
  visibility: visible;
  position: fixed;
  z-index: 1000;
}

.modal-content, .attention-modal-content, .attention-modal-content-mob, .modal-content-mob {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1001;
  max-height: 100vh;
  overflow: auto;
}

.modal-content-mob {
  width: 100%;
  height: 100%;
}

.attention-modal-content-mob {
  width: 80%;
  height: 50%;
}

.body-container, .body-container-mob {
  display: flex;
  margin: 30px;
  justify-content: center;
  min-height: 0px;
  width: 100%;
  height: 100%;
}

.body-container-mob {
  margin: 0px;
}

.attention-container, .attention-container-mob {
  background-color: white;
  border-radius: 8.01966px;
  padding-left: 25px;
  padding-right: 25px;
  width: 484px;
}

.attention-container-mob {
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}

.attention-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 49px;
}

.attention-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
  padding-bottom: 20px;
}

.save-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  text-align: center;
  letter-spacing: -0.2px;
}
.save-btn:hover {
  cursor: pointer;
  background: #00AEAE;
}
.save-btn:focus {
  outline: none;
}
.save-btn:disabled {
  opacity: 0.5;
}

.save-btn-footer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  height: 48px;
  width: 100%;
  border-radius: 0;
  text-align: center;
  letter-spacing: -0.2px;
}
.save-btn-footer:hover {
  cursor: pointer;
  background: #00AEAE;
}
.save-btn-footer:focus {
  outline: none;
}
.save-btn-footer:disabled {
  opacity: 0.5;
}

.fltr-second-btn-footer {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  height: 48px;
  width: 100%;
  border-radius: 0;
  text-align: center;
  letter-spacing: -0.2px;
}

.fltr-img-edit-dnd-container, .fltr-img-edit-dnd-container-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 1px solid #E4EAF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.4px;
  position: relative;
}

.fltr-img-edit-dnd-container-active {
  border: 1px solid #14FFF1;
}

.fltr-img-edit-dnd-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 10px;
  line-height: 21px;
  letter-spacing: -0.165517px;
  color: #6C7177;
  padding-top: 13px;
}

.fltr-img-edit-container {
  position: relative;
  border-radius: 4.40678px;
  display: flex;
  border: 1px solid rgba(242, 249, 255, 0);
}

.fltr-img-edit {
  margin: auto;
  object-fit: cover;
  border: 1px solid #E4EAF0;
  border-radius: 4.40678px;
}
.fltr-img-edit:focus {
  outline: none;
}

.fltr-close-img {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.fltr-close-img:hover {
  opacity: 0.8;
  cursor: pointer;
}

.arrow-closed, .arrow-open {
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 10px;
}

.arrow-closed {
  top: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-open {
  top: 14px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Dropdown-root {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 30px;
  color: white;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: black;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: black;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 85%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 4px 10px;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: block;
  padding: 4px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  cursor: pointer;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 4px 10px;
}

.fltr-dropdown-wrapper {
  display: flex;
  position: relative;
}

.fltr-dropdown {
  visibility: visible;
  position: absolute;
  z-index: 999;
  top: 100%;
}

.library-container {
  display: flex;
  flex-direction: column;
  width: 900px;
}

.section-container {
  display: flex;
  flex-direction: column;
  width: 435px;
  max-height: 100%;
  overflow: visible;
}

.library-header {
  display: flex;
  justify-content: space-between;
}

.library-body {
  display: flex;
  justify-content: space-between;
  min-height: 0;
  margin-top: 30px;
}

.content-types-container, .content-types-container-mob {
  display: flex;
  align-items: center;
}

.content-types-container-mob {
  margin-left: 10px;
  margin-right: 10px;
}

.content-type-selected, .content-type-unselected {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  height: 28px;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
}
.content-type-selected:hover, .content-type-unselected:hover {
  cursor: pointer;
  background: #00AEAE;
}
.content-type-selected:focus, .content-type-unselected:focus {
  outline: none;
}
.content-type-selected:disabled, .content-type-unselected:disabled {
  opacity: 0.5;
}

.content-type-unselected {
  background-color: white;
  color: #7F8489;
}

.fltr-add-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.fltr-add-header-title {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 17px;
  letter-spacing: -0.15px;
  color: #212529;
}

.fltr-add-header-add-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  color: #000000;
  align-items: center;
}

.fltr-add-header-add-container:hover {
  opacity: 0.6;
  cursor: pointer;
}

.fltr-add-header-text-add {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 17px;
  letter-spacing: -0.15px;
  margin-left: 5px;
}

.fltr-items-container {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  max-height: 370px;
}

.fltr-items-container-none-height {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.component-settings-container, .component-settings-container-mob {
  width: 136px;
  background: #FFFFFF;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 0px 0px 10px rgba(147, 148, 157, 0.3);
}

.component-settings-container-mob {
  width: 100px;
}

.component-setting {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  height: 29px;
  width: 100%;
  display: flex;
  align-items: center;
}
.component-setting:hover {
  cursor: pointer;
  background-color: #F5F5F5;
}

.move-to-container {
  max-height: 253px;
}

.component-setting-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 13px;
  margin-right: 13px;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  letter-spacing: -0.15px;
  color: #000000;
  opacity: 0.5;
}

.move-to-input {
  background-color: #F5F5F5;
  height: 23px;
  width: 105px;
  margin: 10px;
  border: 1px solid #E1E1E1;
}
.move-to-input:focus {
  background-color: white;
}

.fltr-date-picker-input {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  height: 30px;
  width: 175px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
}

.fltr-date-picker-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  letter-spacing: -0.15px;
  color: #212529;
}

.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #00DADA;
  color: #fff;
}

.react-datepicker__day--selected:hover {
  background-color: #00DADA;
}

.react-datepicker__day--selected:focus {
  outline: none;
}

.react-datepicker__day--keyboard-selected {
  background-color: #00DADA;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #00DADA;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #00DADA;
}

.publish-container {
  display: flex;
  align-items: center;
}

.publish-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  height: 31px;
  text-align: center;
}
.publish-btn:hover {
  cursor: pointer;
  background: #00AEAE;
}
.publish-btn:focus {
  outline: none;
}
.publish-btn:disabled {
  opacity: 0.5;
}

.dialog-container, .dialog-container-mob {
  background-color: white;
  border-radius: 8.01966px;
  padding-left: 25px;
  padding-right: 25px;
  width: 484px;
}

.dialog-container-mob {
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}

.dialog-publish-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
  padding-bottom: 20px;
}

.dialog-publish-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 49px;
}

.fltr-input-select-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #FFFFFF;
  padding-left: 8px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  color: #212529;
}
.fltr-input-select-container:hover {
  cursor: pointer;
}

.add-content-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-content-container:hover {
  cursor: pointer;
  opacity: 0.7;
}

.plus {
  position: absolute;
  left: 0%;
  right: 72.73%;
  top: 17.65%;
  bottom: 11.76%;
  background: #000000;
}

.dng-img, .dng-img-mob {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 34px;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 3px;
  object-fit: fill;
}

.dng-img:hover, .dng-img-mob:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dng-img-mob {
  width: 40px;
  height: 40px;
}

.wrapper-scroll-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: overlay;
  margin-bottom: 100px;
}

.fltr-status-done {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  line-height: 11px;
  color: #0EB565;
}

.fltr-status-new {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  line-height: 11px;
  color: #1399D3;
}

.fltr-status-in-progress {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  line-height: 11px;
  color: #D8B500;
}

.fltr-status-select-hover:hover, .fltr-status-select-admin:hover, .fltr-status-select-in-progress:hover, .fltr-status-select-new:hover, .fltr-status-select-done:hover {
  cursor: pointer;
  opacity: 0.7;
}

.fltr-status-select-done {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 0.7px solid;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 9px;
  line-height: 11px;
  padding: 6px;
  color: #0EB565;
  fill: #0EB565;
  border-color: #0EB565;
}

.fltr-status-select-new {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 0.7px solid;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 9px;
  line-height: 11px;
  padding: 6px;
  color: #1399D3;
  fill: #1399D3;
  border-color: #1399D3;
}

.fltr-status-select-in-progress {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 0.7px solid;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 9px;
  line-height: 11px;
  padding: 6px;
  color: #D8B500;
  fill: #D8B500;
  border-color: #D8B500;
}

.fltr-status-select-admin {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 0.7px solid;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 9px;
  line-height: 11px;
  padding: 6px;
  color: #212525;
  fill: #212525;
  border-color: #212525;
  text-transform: uppercase;
}

.fltr-close-navigation {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.fltr-close-navigation:hover {
  cursor: pointer;
  opacity: 0.8;
}

.details-footer, .details-footer-mob {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}

.details-footer-mob {
  padding-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  margin-top: auto;
}

.dng-input-container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 200px;
}

.dng-input-container-mob {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  width: 100%;
}

.hover-icon {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.hover-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.internet-disconnected-container, .internet-disconnected-container-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 20px;
}
.login-container {
  width: 26vw;
  margin: 27vh auto;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo-div {
  padding-top: 47px;
  display: flex;
  align-items: center;
}

.login-logo {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 3px solid #000000;
}

.login-logo-text {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  letter-spacing: 0.01em;
  padding-left: 9px;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}

.login-input-div {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.login-input {
  width: 16vw;
  height: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #DBDBDB;
  margin-top: 40px;
}

.login-input:focus {
  outline: none;
  border-bottom: 1px solid #00AEAE;
}

.login-input::placeholder {
  color: #A7A8AA;
  font-size: 14px;
  line-height: 17px;
}

.login-btn {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 16vw;
  height: 40px;
  margin-bottom: 45px;
  margin-top: 35px;
  text-transform: uppercase;
}
.login-btn:hover {
  cursor: pointer;
  background: #00AEAE;
}
.login-btn:focus {
  outline: none;
}
.login-btn:disabled {
  opacity: 0.5;
}

.login-btn:disabled {
  color: rgb(160, 203, 210);
  background-color: rgba(0, 218, 218, 0.4117647059);
}

.err-msg {
  font-size: 13px;
  padding-top: 50px;
  color: #f75130;
  width: 70%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .login-container {
    width: 50vw;
    margin: 35vh auto;
  }
  .login-input {
    width: 36vw;
  }
  .login-btn {
    width: 36vw;
  }
  .login-logo-div {
    padding-top: 40px;
  }
}
@media screen and (max-width: 800px) {
  .login-container {
    width: 50vw;
    margin: 35vh auto;
  }
  .login-input {
    width: 36vw;
  }
  .login-btn {
    width: 36vw;
  }
  .login-logo-div {
    padding-top: 40px;
  }
}
@media screen and (max-width: 450px) {
  .login-container {
    width: 80vw;
    margin: 12vh auto;
  }
  .login-input {
    width: 60vw;
  }
  .login-btn {
    width: 60vw;
  }
  .login-logo-div {
    padding-top: 40px;
  }
}
@media screen and (max-height: 450px) {
  .login-div {
    margin: 4vh auto;
  }
}
@media screen and (max-height: 400px) {
  .login-container {
    width: 80vw;
    margin: 2vh auto;
  }
  .login-input {
    width: 60vw;
  }
  .login-input-div {
    padding-bottom: 8px;
  }
  .login-btn {
    width: 60vw;
  }
  .login-logo-div {
    padding-top: 20px;
  }
}
.pack-list, .pack-list-in-group {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.pack-list-in-group {
  overflow: visible;
  margin-left: 17px;
}

.pack-list-content {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0);
  border-top: 1px solid #F0F0F0;
  flex-shrink: 0;
}

.pack-list-content:hover {
  cursor: pointer;
  border-top: 1px solid #00DADA;
  border-bottom: 1px solid #00DADA;
}

.pack-list-item, .pack-list-item-active {
  display: flex;
  align-items: center;
  height: 51px;
}

.pack-list-item-active {
  background-color: #FAFAFA;
}

.pack-list-header-arrow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pack-list-value-name {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pack-list-value-elements {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  margin-left: 5px;
}

.pack-details-container {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 468px;
  padding-left: 24px;
  padding-right: 24px;
}

.pack-details-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 27px;
}

.pack-details-body {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
}

.pack-details-sub-body {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  width: 100%;
}

.pack-list-img {
  width: 54px;
  height: 40px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  margin-left: 8px;
  object-fit: cover;
}

.pack-details-footer {
  display: flex;
  padding-top: 50px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 17px;
}

.elements-list {
  display: flex;
  overflow: auto;
}

.element-card-container {
  flex-shrink: 0;
  max-width: 70px;
  max-height: 93px;
  border-radius: 4px;
  border: 1px solid rgba(242, 249, 255, 0);
  margin-left: 10px;
  position: relative;
}

.element-card-img {
  position: relative;
  border-radius: 4.40678px;
  height: 93px;
  width: 70px;
  display: flex;
}

.element-card-name {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 17px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  position: absolute;
  background: #333333;
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  bottom: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.element-details-container {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 468px;
  padding-left: 29px;
  padding-right: 25px;
}

.element-details-body {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.element-details-sub-body {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 226px;
}

.element-add-card-container {
  border-radius: 4px;
  width: 70px;
  height: 93px;
  flex-shrink: 0;
  background: #FFFFFF;
  border: 1.10345px solid #E4EAF0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.element-add-card-container:hover {
  cursor: pointer;
  border: 1.10345px solid #00DADA;
  -webkit-transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.element-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.element-list-item {
  display: flex;
  align-items: center;
  height: 51px;
  background-color: white;
}

.element-list-item-in-pack {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: white;
}

.element-list-img-in-pack {
  width: 30px;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 3px;
  margin-left: 7px;
  object-fit: cover;
}

.element-list-img {
  width: 54px;
  height: 40px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  margin-left: 8px;
  object-fit: cover;
}

.group-header {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 12px;
}

.group-footer {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 7px;
  border-top: 1px solid #F0F0F0;
}
.group-footer-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  text-transform: uppercase;
  color: #00DADA;
  font-size: 10px;
  text-align: center;
  letter-spacing: -0.15px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
.group-footer:hover {
  cursor: pointer;
  opacity: 0.8;
}

.group-header-name {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  padding-left: 9px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #212529;
}

.group-list {
  display: flex;
  flex-direction: column;
  padding-bottom: 110px;
}

.today-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
}

.today-list {
  display: flex;
  flex-direction: column;
}

.today-header {
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-left: 16px;
  margin-bottom: 25px;
}

.today-header-name {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #212529;
}

.tags-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: white;
  padding: 38px 29px;
  border-radius: 10px;
  overflow: auto;
  min-width: 600px;
}

.tags-list-container {
  display: flex;
  flex-shrink: 0;
  overflow-x: auto;
}

.tag-text {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 140%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
}

.add-tag-text {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15px;
  color: #000000;
}

.tag-locale-column {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100px;
}

.tag-delete-column {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 20px;
}

.tags-add-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  padding-top: 16px;
  width: 100px;
  font-weight: 500;
  align-items: center;
}
.tags-add-container:hover {
  cursor: pointer;
  opacity: 0.5;
}

.tag-input, .tag-input-active {
  background: rgba(255, 255, 255, 0);
  width: 70px;
  height: 23px;
  padding-left: 10px;
  margin-left: 5px;
  margin-right: 7px;
  border-radius: 3px;
  border: 1px solid rgba(228, 234, 240, 0);
}
.tag-input::placeholder, .tag-input-active::placeholder {
  color: black;
}
.tag-input-active {
  border: 1px solid #cccccc;
}

.tags-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  min-height: 30px;
  padding: 1px 4px;
  margin-top: 7px;
  width: 100%;
}

.tag-badge {
  display: flex;
  align-items: center;
  background: #B3F4F4;
  padding-left: 5px;
  padding-right: 5px;
  margin: 3px;
  height: 17px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.15px;
  color: #212529;
}

.close-tag {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 20px;
  height: 12px;
}
.close-tag:hover {
  cursor: pointer;
  opacity: 0.5;
}

.tag-list-item {
  display: flex;
  align-items: center;
  height: 51px;
  background-color: white;
}

.tag-list-img {
  width: 54px;
  height: 40px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  margin-left: 8px;
  object-fit: cover;
}

.tags-list-filter-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  padding-bottom: 20px;
  padding-left: 50px;
}

.tags-list-filter-btn, .tags-list-filter-btn-disabled {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 10px;
  line-height: 12px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  padding: 8px 10px;
}
.tags-list-filter-btn:hover, .tags-list-filter-btn-disabled:hover {
  cursor: pointer;
  background: #00AEAE;
}
.tags-list-filter-btn:focus, .tags-list-filter-btn-disabled:focus {
  outline: none;
}
.tags-list-filter-btn:disabled, .tags-list-filter-btn-disabled:disabled {
  opacity: 0.5;
}

.tags-list-filter-btn:hover, .tags-list-filter-btn-disabled:hover {
  cursor: pointer;
  background-color: #B3F4F4;
  color: #7F8489;
}

.tags-list-filter-btn-disabled {
  background-color: #FFFFFF;
  color: #7F8489;
}

.settings-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
}

.remote-config-container {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 433px;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.remote-config-container-mob {
  width: 90%;
}

.remote-config-header {
  display: flex;
  justify-content: space-between;
}

.remote-config-body {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.remote-config-header-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #212525;
}

.remote-config-switcher-text {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #7F8489;
}

.remote-config-prop-container {
  display: flex;
  flex-direction: column;
}

.remote-config-prop-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15px;
  padding-bottom: 5px;
  color: #7F8489;
}

.remote-config-prop-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15px;
  color: #212525;
}

.remote-config-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
}

.requests-container, .requests-container-mob {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  width: 796px;
}

.requests-container-mob {
  width: 100%;
}

.requests-list, .requests-list-mob {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  padding-left: 21px;
}

.requests-list-mob {
  border-radius: 0;
  padding-left: 0px;
  margin-top: 12px;
}

.request, .request-mob {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #E1E1E1;
  padding-right: 30px;
}

.request:hover, .request-mob:hover {
  cursor: pointer;
  background: rgba(250, 250, 250, 0.9);
}

.request-mob {
  padding-right: 14px;
}

.requests-table-header, .requests-table-header-mob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 17px;
  padding-top: 40px;
  flex-shrink: 0;
  padding-right: 30px;
}

.requests-table-header-mob {
  padding-right: 14px;
}

.header-hover:hover, .request-list-header-status:hover, .request-list-header-agent:hover, .request-list-header-end-in:hover, .request-list-header-id:hover, .request-list-header-contact:hover, .request-list-header-date:hover, .request-list-header-img-mob:hover, .request-list-header-img:hover {
  cursor: pointer;
  opacity: 0.6;
}

.request-list-header-img {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  margin-left: 3px;
}

.request-list-header-img-mob {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  margin-left: 14px;
}

.request-list-value-img {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
}

.request-list-header-date {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75px;
  display: flex;
  align-items: center;
}

.request-list-value-date {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75px;
  display: flex;
  align-items: center;
}

.request-list-header-contact {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  display: flex;
  align-items: center;
}

.request-list-value-contact {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  display: flex;
  align-items: center;
}

.request-list-header-id {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40px;
  display: flex;
  align-items: center;
}

.request-list-value-id {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40px;
  display: flex;
  align-items: center;
}

.request-list-header-end-in {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  display: flex;
  align-items: center;
}

.request-list-value-end-in {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  display: flex;
  align-items: center;
}

.request-list-header-agent {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  display: flex;
  align-items: center;
}

.request-list-value-agent, .request-list-value-agent-mob {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  display: flex;
  align-items: center;
}

.request-list-value-agent-mob {
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  letter-spacing: -0.1px;
  color: #7F8489;
}

.request-list-header-status {
  display: flex;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65px;
}

.request-list-value-status {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 12px;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.requests-list-img, .requests-list-img-mob {
  width: 40px;
  height: 40px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  margin-left: 3px;
  object-fit: cover;
}

.requests-list-img-mob {
  margin-left: 14px;
}

.requests-list-header-arrow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.request-details-header, .request-details-header-mob {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 14px 25px 9px;
}

.request-details-header-mob {
  padding: 8px 16px 8px;
}

.request-details-container, .request-details-container-mob {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 468px;
}

.request-details-container-mob {
  width: 100%;
  height: 100%;
}

.request-details-body, .request-details-body-mob {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
}

.request-details-body-mob {
  align-items: center;
  height: 100%;
}

.request-details-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-details-header-navigation-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.request-details-header-navigation-info-request {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #FFFFFF;
}

.request-details-header-navigation-info-makes {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: #7F8489;
}

.request-details-header-end-in-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.request-details-header-end-in-title {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  line-height: 11px;
  color: #7F8489;
}

.request-details-header-end-in-value {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
  color: white;
}

.request-before-after-container, .request-before-after-container-mob {
  display: flex;
  marginTop: 6px;
}

.request-before-after-container-mob {
  marginTop: 15px;
  width: 100%;
  justify-content: space-between;
}

.request-details-contact-date-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15px;
  color: #212529;
}

.request-details-contact-date-text:hover {
  cursor: pointer;
  opacity: 0.8;
}

.request-details-description {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  width: 100%;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.15px;
  color: #7F8489;
  padding-top: 6px;
  padding-bottom: 15px;
}

.block-request-details {
  position: absolute;
  backdrop-filter: blur(2px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.download-img-block {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  height: 24px;
  position: absolute;
  background-color: #00DADA;
  bottom: -2px;
  left: 0px;
  border-radius: 0px 0px 4.4px 4.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  opacity: 0.94;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  width: 102%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.download-img-block:hover {
  cursor: pointer;
  opacity: 1;
}

/*# sourceMappingURL=index.css.map */
